import React, { useEffect, useState } from 'react';
import { useGeoLocation } from 'src/shared/hooks/use-geo-location';

import { useAuthStore } from 'src/shared/model/Store/Auth';
import { HeaderGlobal } from '@uremont/ui-kit';
import { ThemeProvider } from 'styled-components';
import { defaultTheme } from '@uremont/ui-themes';
import { MenuItems } from 'src/features/AppLayout/components/Header/lib/MenuItems/MenuItems';
// import { SliderBanner } from 'src/features/AppLayout/components/Header/lib/SliderBanner/SliderBanner'; // TODO: Временно скрыта, будет возвращено по запросу
import { contacts } from 'src/shared/constants/contacts';
import { setCookie, getCookie, removeCookie } from '@uremont/erp-ui';
import { EXPIRES_COOKIE_YEAR } from 'src/shared/constants/consts';
import { EMPTY_STRING } from '@shared/constants/fallbacks';
import { pathConfig } from '@shared/constants/pathConfig';
import { ProfileMenuCustomer } from '@features/AppLayout/components/Header/lib/ProfileMenuCustomer';
import { ProfileMenuAutopark } from '@features/AppLayout/components/Header/lib/ProfileMenuAutopark';
import { ProfileMenuService } from '@features/AppLayout/components/Header/lib/ProfileMenuService';
import { ModalForm } from '@features/AppLayout/components/Header/lib/ModalForm';
import { useProfileStore } from '@shared/model/Store/Profile/store';
import { updateCityName } from '@features/AppLayout/components/Header/lib/utils/updateCityName';
import { updateCountryName } from '@features/AppLayout/components/Header/lib/utils/updateCountryName';
import { COOKIE_NAME, USER_ROLE } from '@shared/constants/enums';
import { useCountriesStore } from '@shared/model/Store';
import { getDomainForCookie } from '@utils/getDomainForCookie';
import {
  UREMONT_CUSTOMER_URL,
  UREMONT_MAIN_URL,
  UREMONT_SERVICE_URL
} from '@shared/constants/links';
import { City } from './types';

export const Header = () => {
  const [getCurrentCountry] = useCountriesStore(state => [state.getCurrentCountry]);
  const [isShowModal, setIsShowModal] = useState(false);
  const UREMONT_URL = process.env.REACT_APP_UREMONT_URL ?? EMPTY_STRING;
  const [isAuthorized] = useAuthStore(state => [state.isAuthorized]);
  const {
    selectedCityName,
    countries,
    countryId,
    selectedCityId,
    getPopularCitiesData,
    cities,
    setSelectedCity,
    inputCityValue,
    handleInputCityChange,
    searchCities,
    dropdownOpen,
    setDropdownOpen,
    getCountriesData,
    handleCityClick,
    currentCountry
  } = useGeoLocation();

  const [profile, getProfileInfoData] = useProfileStore(state => [
    state.profile,
    state.getProfileInfoData
  ]);

  const token = getCookie(COOKIE_NAME.AUTH_TOKEN);
  const domain = getDomainForCookie();

  const handleLogout = () => {
    if (!token) {
      return null;
    }

    removeCookie(COOKIE_NAME.AUTH_TOKEN, domain);
    removeCookie(COOKIE_NAME.REFRESH_TOKEN, domain);

    const { origin } = window.location;

    window.location.replace(origin);

    return null;
  };

  const handleSelectCity = (city: City) => {
    setSelectedCity({
      id: city.id,
      country_id: city.country_id,
      region_id: city.region_id,
      title: city.name
    });

    setCookie(COOKIE_NAME.CITY_ID, String(city.id), EXPIRES_COOKIE_YEAR, domain);
    setCookie(COOKIE_NAME.COUNTRY_ID, String(city.country_id), EXPIRES_COOKIE_YEAR, domain);
    setCookie(COOKIE_NAME.CITY_NAME, city.name, EXPIRES_COOKIE_YEAR, domain);
  };

  const handleSelectSearchCity = (city: City) => {
    handleCityClick(city.name);
    handleSelectCity(city);
  };

  const handleSelectCountry = (id: number) => {
    getPopularCitiesData(id);
    setCookie(COOKIE_NAME.COUNTRY_ID, String(id), EXPIRES_COOKIE_YEAR, domain);
  };

  // const bannerHeader = () => <SliderBanner small />; // TODO: Временно скрыта, будет возвращено по запросу
  // const bannerSidebar = () => <SliderBanner />; // TODO: Временно скрыта, будет возвращено по запросу
  const bannerHeader = () => <div />;
  const bannerSidebar = () => <div />;

  const countryList = updateCountryName(countries);
  const cityList = updateCityName(cities);
  const searchCityList = updateCityName(searchCities);

  const handleFocus = () => {
    setDropdownOpen(true);
  };

  const handleBlur = () => {
    setTimeout(() => {
      setDropdownOpen(false);
    }, 100);
  };

  const isShowSearchOptions = dropdownOpen && !!searchCityList.length;

  const handleModalShow = () => setIsShowModal(true);
  const handleModalHide = () => setIsShowModal(false);

  const renderProfileMenu = () => {
    if (profile.roles?.includes(USER_ROLE.AUTOPARK)) {
      return <ProfileMenuAutopark onOpenModal={handleModalShow} />;
    }

    if (profile.roles?.includes(USER_ROLE.SERVICE)) {
      return <ProfileMenuService />;
    }

    return <ProfileMenuCustomer />;
  };

  const chatLink = profile.roles?.includes(USER_ROLE.SERVICE)
    ? `${UREMONT_SERVICE_URL}/cabinet/service/chat`
    : `${UREMONT_CUSTOMER_URL}/cabinet/customer/chat`;

  useEffect(() => {
    const cityCookieId = getCookie(COOKIE_NAME.CITY_ID);
    const countryCookieId = getCookie(COOKIE_NAME.COUNTRY_ID);

    getPopularCitiesData(countryCookieId ? Number(countryCookieId) : currentCountry?.id);

    const cityCookie = cities.find(({ id }) => id === Number(cityCookieId));
    if (cityCookie) {
      setSelectedCity(cityCookie);
      setCookie(COOKIE_NAME.CITY_NAME, cityCookie.title, EXPIRES_COOKIE_YEAR, domain);
    }
  }, []);

  useEffect(() => {
    getProfileInfoData();

    if (!countryId) {
      getCurrentCountry();
    }
  }, []);

  useEffect(() => {
    if (!countries.length) {
      getCountriesData();
    }
  }, [countries]);

  return (
    <ThemeProvider theme={defaultTheme}>
      <HeaderGlobal
        authLink={`${UREMONT_URL}${pathConfig.EnterPath}`}
        cartLink='/cart'
        chatLink={chatLink}
        cityId={selectedCityId}
        cityList={cityList}
        cityName={selectedCityName}
        contacts={contacts}
        countryId={Number(countryId)}
        countryList={countryList}
        isAuth={isAuthorized}
        isShowSearchOptions={isShowSearchOptions}
        mainLink={UREMONT_MAIN_URL}
        notificationLink={`${UREMONT_URL}/notifications`}
        onBlur={handleBlur}
        onChange={handleInputCityChange}
        onFocus={handleFocus}
        onLogout={handleLogout}
        onSelectCity={handleSelectCity}
        onSelectCountry={handleSelectCountry}
        onSelectSearchCity={handleSelectSearchCity}
        profile={profile}
        renderHeaderBanner={bannerHeader}
        renderMenuItems={MenuItems}
        renderProfileItems={renderProfileMenu}
        renderSidebarBanner={bannerSidebar}
        searchOptions={searchCityList}
        searchValue={inputCityValue}
      />
      {isShowModal && <ModalForm onClose={handleModalHide} />}
    </ThemeProvider>
  );
};
