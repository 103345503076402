import { ReactComponent as NotFoundImg } from '@assets/notFoundImg.svg';
import { ReactComponent as IconChevronRight } from '@assets/IconChevronRight.svg';
import { uremontLinks } from '@shared/constants/links';
import styles from './styles.module.scss';

export const PageNotFound = () => {
  return (
    <div className={styles.page}>
      <div className={styles.page_image}>
        <NotFoundImg />
      </div>
      <div className={styles.page_content}>
        <div className={styles.page_content_title}>404</div>
        <div className={styles.page_content_subtitle}>
          <div>Дорога свернула куда-то не туда </div>
          <div className={styles.page_content_subtitle_description}>
            Такой страницы не существует
          </div>
        </div>
        <a className={styles.page_link} href={uremontLinks.mainUremont}>
          <div>Вернуться на главную</div>
          <IconChevronRight />
        </a>
      </div>
    </div>
  );
};
