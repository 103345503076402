import React from 'react';
import { Route, Navigate } from 'react-router-dom';
import { PageLayout } from 'src/features/AppLayout/components/PageLayout';
import { Page } from 'src/pages/lib/types';
import { pathConfig } from '@shared/constants/pathConfig';

export const renderPages = (pages: Page[]) => {
  return (
    <>
      {pages.map(({ Component, path }) => {
        if (path === pathConfig.PageNotFound) {
          return <Route element={<Component />} key={path} path={path} />;
        }

        return <Route element={<PageLayout children={<Component />} />} key={path} path={path} />;
      })}
      <Route element={<Navigate replace to='/404' />} path='*' />
    </>
  );
};
