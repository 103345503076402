import { ToastContainer } from 'react-toastify';
import { Header } from '@features/AppLayout';
import { FooterGlobalNew } from '@uremont/ui-kit';
import { ThemeProvider } from 'styled-components';
import { defaultTheme } from '@uremont/ui-themes';
import { dataTestId } from '@shared/constants/dataTestId';
import {
  UREMONT_URL,
  UREMONT_MAIN_URL,
  FRONT_UREMONT_SERVICE_URL,
  CORPARK_URL,
  INSURANCE_URL
} from '@shared/constants/links';
import { EMPTY_STRING } from 'src/shared/constants/fallbacks';
import { AuthRefresher } from '../AuthRefresher';
import { AUTO_CLOSE_TIME } from './lib/constants/toast';
import { PageLayoutProps } from './types';
import 'react-toastify/dist/ReactToastify.css';
import styles from './styles.module.scss';

export const PageLayout = ({ children }: PageLayoutProps) => {
  return (
    <>
      <Header />
      <div className={styles.container} data-testid={dataTestId.CONTENT}>
        {children}
      </div>
      <ToastContainer
        autoClose={AUTO_CLOSE_TIME}
        className={styles.toast}
        closeOnClick
        hideProgressBar={true}
        pauseOnFocusLoss
        position='bottom-left'
      />
      <ThemeProvider theme={defaultTheme}>
        <FooterGlobalNew
          autopartsLink={EMPTY_STRING}
          corparkLink={CORPARK_URL}
          insuranceLink={INSURANCE_URL}
          serviceLink={FRONT_UREMONT_SERVICE_URL}
          uremontLink={UREMONT_URL}
          uremontMainLink={UREMONT_MAIN_URL}
        />
      </ThemeProvider>
      <AuthRefresher />
    </>
  );
};
